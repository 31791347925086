<template>
  <div class="mt-2 mb-6">
    <h2 class="text-h4 mb-2">
      {{ title }}
    </h2>
    <p
      v-if="subtitle"
      class="text-body-1 mt-2"
    >
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: null },
  },
};
</script>
