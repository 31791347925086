<template>
  <v-row>
    <v-col>
      <page-title
        title="My List"
        subtitle="Airfields you'd like to visit, and all the airfields you've visited before"
      />

      <airfield-filters
        :apply-filters="applyFilters"
        :show-visited="true"
      />

      <airfield-list-header />

      <div
        v-for="(item, i) in items"
        :key="i"
      >
        <sponsor-card
          v-if="item.isSponsor"
          :data="item"
        />
        <airfield-card
          v-else
          :data="item"
        />
      </div>

      <div
        v-if="airfields.length > 0"
        class="py-4 text-center text-body-2"
      >
        {{ airfields.length }} airfields shown
      </div>
      <div
        v-else-if="airfields.length == 0"
        class="px-4 py-12 text-center text-body-1"
      >
        <p>
          We couldn't find any airfields matching your selected filters, please
          try again with less filters selected.
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import AirfieldCard from "@/components/AirfieldCard";
import AirfieldFilters from "@/components/AirfieldFilters";
import AirfieldListHeader from "@/components/AirfieldListHeader.vue";
import SponsorCard from "@/components/SponsorCard";
import PageTitle from "../components/PageTitle.vue";

export default {
  name: "MyList",

  components: {
    PageTitle,
    AirfieldCard,
    AirfieldFilters,
    AirfieldListHeader,
    SponsorCard,
  },

  mounted() {
    this.fetchAirfields();
  },

  computed: {
    ...mapGetters({
      airfields: "airfields/myItems",
    }),
    items() {
      const airfields = this.airfields;
      const items = [];
      const chunkSize = 8;
      var i, j;
      var airfieldsChunk = [];

      if (airfields.length == 0) {
        items.push(this.getSponsor());
        return items;
      }

      for (i = 0, j = airfields.length; i < j; i += chunkSize) {
        airfieldsChunk = airfields.slice(i, i + chunkSize);

        items.push(this.getSponsor());
        items.push(...airfieldsChunk);
      }

      return items;
    },
  },

  methods: {
    async fetchAirfields() {
      this.$store.dispatch("airfields/fetchMyList");
    },

    async applyFilters(filters) {
      this.$store.dispatch("airfields/applyMyFilters", filters).finally(() => {
        this.showFilters = false;
        this.applyingFilters = false;
      });
    },

    getSponsor() {
      return {
        isSponsor: true,
        title: "Sponsored by AirfieldHub",
        tagline:
          "Simple airfield management with online PPR, booking out, and landing fees payments.",
        url: "https://airfieldhub.com/?utm_source=airfieldlist.com&utm_campaign=launch",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
